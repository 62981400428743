import React from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";

import { contactFormValidationSchema } from "helpers/validation";

const ContactForm = ({ submitIt }) => (
  <Formik
    initialValues={{
      name: "",
      email: "",
      phone: "",
      subject: "General Question",
      message: "",
    }}
    validationSchema={contactFormValidationSchema}
    onSubmit={(values, actions) => {
      // alert(JSON.stringify(values, null, 2));
      actions.setSubmitting(true);
      submitIt(values, actions);
    }}
  >
    {props => (
      <Form className="form">
        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="namefirst">Name:</label>
              <Field
                name="name"
                placeholder="Name"
                className="form-control"
                required
              />
              <ErrorMessage
                name="name"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="email">Email Address:</label>
              <Field
                name="email"
                placeholder="Your Email"
                type="email"
                className="form-control"
                required
              />
              <ErrorMessage
                name="email"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="phone">Phone:</label>
              <Field name="phone" className="form-control" />
              <ErrorMessage
                name="phone"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="subject">Subject:</label>
              <Field
                name="subject"
                as="select"
                className="form-control"
                required
              >
                <option value="General Question">General: Question</option>
                <option value="General Comment">General: Comment</option>
                <option value="On-Site Training Inquiry">
                  On-Site Training Inquiry
                </option>
              </Field>
              <ErrorMessage
                name="subject"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <div className="form-row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="message">Message:</label>
              <Field
                name="message"
                as="textarea"
                type="textarea"
                rows={6}
                className="form-control"
              />
              <ErrorMessage
                name="message"
                component="div"
                className="text-danger input-feedback"
              />
            </div>
          </div>
        </div>

        <button
          type="submit"
          className="btn btn-primary"
          disabled={props.isSubmitting}
        >
          Submit
        </button>
      </Form>
    )}
  </Formik>
);

export default ContactForm;
