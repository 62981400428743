import React, { useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaPhone,
  FaMapMarkerAlt,
  FaRegEnvelope,
} from "react-icons/fa/index.esm";
import { Helmet } from "react-helmet";
import axios from "axios";

import ContactForm from "components/forms/ContactForm";
import { HTMLContent } from "components/Content";
import Layout from "components/Layout";
import SEO from "components/Seo";
import { cartContext } from "providers/CartProvider";
// import Banner from "../components/Banner";

const ContactPage = () => {
  const siteContext = useContext(cartContext);

  const handleSubmit = async (vals, actions) => {
    try {
      const mailURL = `${process.env.GATSBY_API_URL}/process/contactform`;

      const sendResponse = await axios.post(mailURL, vals);

      if (sendResponse.status === 200) {
        actions.setSubmitting(false);
        actions.resetForm();
        return siteContext.makeToast("Your message was sent!", "success");
      }

      actions.setSubmitting(false);
      return siteContext.makeToast(
        "There was a problem sending your message. Please try again in a moment, or call us during business hours.",
        "info"
      );
    } catch (e) {
      actions.setSubmitting(false);
      siteContext.makeToast(
        "There was a server error sending your message. Please try again in a few moments.",
        "info"
      );
    }

    // /process/contactform
  };

  const handleConfirm = (vals, actions) => {
    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.GATSBY_RECAPTCHA_PUBLIC, { action: "contact" })
          .then(token => {
            // console.log("token: ", token);
            handleSubmit({ token, ...vals }, actions);
          });
      });
    }
  };

  const data = useStaticQuery(graphql`
    query contactPageQuery {
      prismicSharedConfigs {
        data {
          classroom_address {
            text
          }
          contact_email
          facebook_url {
            url
          }
          instagram_url {
            url
          }
          range_address {
            text
            html
          }
          phone_number
          twitter_url {
            url
          }
        }
      }
    }
  `);

  const {
    contact_email,
    phone_number,
    range_address,
    classroom_address,
  } = data.prismicSharedConfigs.data;

  const classroomAddy = `<p>${classroom_address.text.replace(
    /\n/g,
    "<br />"
  )}</p>`;

  const rangeAddy = `<p>${range_address.text.replace(/\n/g, "<br />")}</p>`;

  return (
    <Layout pageInfo={{ pageName: "contact-us" }}>
      <SEO title="Contact Us" />
      <Helmet>
        <script
          src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_PUBLIC}`}
        />
      </Helmet>
      {/* <Banner /> */}
      <section>
        <Container>
          <Row>
            <Col>
              <h2>Contact Us</h2>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="7">
              <p className="intro">
                Got a question, comment, or an inquiry about on-site training?
                Get in touch with us using the form below, or give us a call.
              </p>

              <ContactForm submitIt={handleConfirm} />
            </Col>

            <Col xs="12" md={{ span: 4, offset: 1 }} className="sidebar">
              <h3>Classroom</h3>

              <HTMLContent className="address" content={classroomAddy} />

              <p className="address-links">
                <a
                  href="https://goo.gl/maps/RK1spbPsMrE3j5bR7"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-directions"
                >
                  <FaMapMarkerAlt /> Get Directions
                </a>{" "}
                <br />
                <a href={`tel:${phone_number}`}>
                  <FaPhone /> {phone_number}
                </a>{" "}
                <br />
                <a href={`mailto: ${contact_email}`}>
                  <FaRegEnvelope /> {contact_email}
                </a>
              </p>

              <hr />

              <p>
                <b>DCJS ID:</b> 88-1202
              </p>

              <hr />

              {/* <h3>Range</h3>

              <HTMLContent className="address" content={rangeAddy} />

              <p className="address-links">
                <a
                  href="https://goo.gl/maps/Fcufj9cxtiqp87WX6"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-directions"
                >
                  <FaMapMarkerAlt /> Get Directions
                </a>
              </p> */}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export default ContactPage;
